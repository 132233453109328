.footer, .container-fluid:after{
    background-color: #1f2224;
    color: white;
    height: auto !important;
    min-width: 255px;
}

.footer-title {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}

.col-contact {
    min-width: 235px;
    max-width: 235px;
    position: absolute;
    right: 0;
}

.col-contact>.row>a {
    text-decoration: none;
    color: white;
}

.created-by {
    justify-content: center;
}

@media (max-width: 680px) {
    .created-by {
        justify-content: left;
    }
}

@media (max-width: 565px) {
    .created-by {
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .col-sm-12 {
        position: relative;
    }
}

.contactFocus {
    border: 2px solid white;
    border-radius: 50%;
    animation: scale 1s infinite alternate;
}
  
@keyframes scale {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}