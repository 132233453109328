.ProjectTitle {
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    font-size: 50px;
    letter-spacing: -1.2px;
    word-spacing: -3.6px;
    color: #000000;
    font-weight: 700;
    text-decoration: none solid rgb(68, 68, 68);
    font-style: normal;
    font-variant: small-caps;
    text-transform: none;
}

.col-card{
    min-width: 225px;
}

.card {
    min-width: 225px;
    height: 100%;
}

.btn-link {
    vertical-align: baseline;
    padding-left: 0;
    padding-right: 0;
    max-width: 67px;
    min-width: 67px;
}

.github-button{
    padding-top: 1rem!important;
}

.card-title{
    text-align: center;
}

.project-links{
    text-align: center;
}

@media (min-width: 82px){
    .more-details.col-sm-4{
        left: -15px;
    }
}
@media (max-width: 575px){
    .more-details.col-sm-4{
        left: -0;
    }
}