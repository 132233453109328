.row-c {
    text-align: center;
}

.container {
    font-size: 25px;
}

/* .col-photo {
    overflow: hidden;
    width: 340px;
    height: 490px;
    min-width: 300px;
    min-height: 450px;
}

.prof-photo {
    position: absolute;
    width: 100%;
}

.military-photo{
    position:absolute;
    width: 100%;
} */
.military-photo {
    max-width: 100%;
    max-height: 220px;
    min-width: 240px;
    max-width: 240px;
}

.col-text {
    min-width: 300px;
}

@media (min-width: 992px) {
    .container {
        height: 1000px;
    }
}

/*
.row-body {
    font-size: calc(4px + 1.0vw);
    max-width: 100%;
}*/

.about-description>span {
    font-weight: bold;
}