.NavLink {
    font-size:large;
    font-weight: bold;
}

.NavingationBar {
    background-color: #1f2224;
}

.white {
    color:white;
}

.NavBrand {
    font-size:25px;
}

.navbar-white{
    background-color: white;
}